.sample {
  background: rgb(233, 236, 238);

  .text-center {
    p {
      margin: 0;
    }
  }

  img.esig-signowise-logo {
    max-height: 65px;
    max-width: 390px;
    margin-bottom: 20px;
  }

  .esig-digitise p {
    margin: 40px 0px 0px 0px;
    font-size: 17px;
  }

  .esig-digitise h3 {
    text-transform: none;
    font-size: 24px;
    padding: 10px 60px;
  }

  .esig-product-line-comparison div.esig-table-header {
    font-weight: bolder;
    background-color: transparent;
    padding: 0px;
    margin-top: 40px;
  }

  .esig-product-line-comparison div.esig-table-header div {
    padding: 15px 5px;
  }

  .esig-product-line-comparison div.esig-table-header img {
    max-width: 100%;
    max-height: 100%;
  }

  .esig-product-line-comparison div.esig-table-content {
    display: table;
    height: 100%;
    width: 100%;
  }

  .esig-product-line div.row {
    font-size: 16px;
    text-align: center;
    padding: 15px 5px;
  }

  .esig-product-line-comparison div.esig-table-content {
    padding: 0px;
  }

  .esig-product-line-comparison div.esig-table-content:nth-child(even) {
    background-color: #f6f6f6;
  }

  .esig-product-line-comparison div.esig-table-content div {
    float: none;
    display: table-cell;
    vertical-align: middle;
    padding: 15px 5px;
  }

  .esig-product-line-comparison div.esig-table-content div {
    border-color: #20303b;
    border-style: solid;
  }

  .esig-product-line-comparison div.esig-table-content div:nth-child(1) {
    border-width: 0px;
  }

  .esig-product-line-comparison div.esig-table-content div:nth-child(2) {
    border-width: 0px 2px 0px 2px;
  }

  .esig-product-line-comparison div.esig-table-content div:nth-child(3) {
    border-width: 0px 2px 0px 0px;
  }

  .esig-product-line-comparison div.buttons div:nth-child(2),
  .esig-product-line-comparison div.buttons div:nth-child(3) {
    border-bottom-width: 2px;
  }

  .esig-product-line-comparison div.esig-table-header div:nth-child(1) {
    border-width: 0px;
  }

  .esig-product-line-comparison div.esig-table-header div:nth-child(2) {
    border-width: 2px 2px 0px 2px;
  }

  .esig-product-line-comparison div.esig-table-header div:nth-child(3) {
    border-width: 2px 2px 0px 0px;
  }

  .esig-product-line-comparison div.esig-table-sub div:first-child {
    font-weight: bolder;
    font-size: 18px;
    vertical-align: middle;
    text-align: left;
  }

  .esig-product-overview {
    background-color: #f2f2f2;
    padding: 60px 0px;
  }

  .esig-product-overview div.list {
    font-size: 20px;
    margin: 40px 40px 0px 40px;
    padding: 0px 40px;
  }

  .esig-product-overview div.list div.item {
    display: list-item;
    list-style: none;
    padding: 10px 0px 10px 40px;
    position: relative;
  }

  .esig-product-overview div.list div.item::before {
    content: '';
    display: inline-block;
    height: 34px;
    width: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    bottom: 0px;
    left: 0px;
    vertical-align: middle;
  }

  .esig-for-banks#swb {
    padding: 60px 0px;
  }

  p {
    margin: 40px 0px 30px 0px;
    font-size: 17px;
  }

  h2 {
    margin: 40px 0px 20px 0px;
    font-size: 28px;
  }

  .full-image.signed-doc {
    position: relative;
    display: block;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .esig-benefits {
    padding: 60px 0px;
  }

  .esig-benefits p {
    margin: 20px 0px 20px 0px;
    font-size: 15px;
  }

  .signed-doc .click-here-ds,
  .signed-doc .click-here-ff,
  .signed-doc .click-here-es {
    opacity: 0;
  }

  .signed-doc .click-here-ds {
    position: absolute;
    top: 15%;
    left: 2%;
    width: 45%;
    height: 125px;
    cursor: pointer;
    z-index: 9999;
  }

  .signed-doc .click-here-ff {
    position: absolute;
    top: 40%;
    left: 29%;
    width: 35%;
    height: 125px;
    cursor: pointer;
    z-index: 9999;
  }

  .signed-doc .click-here-es {
    position: absolute;
    top: 65%;
    left: 29%;
    width: 35%;
    height: 175px;
    cursor: pointer;
    z-index: 9999;
  }

  .signed-doc .signed-content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.6);
  }

  .signed-doc .signed-content img {
    margin: 100px 20px;
  }

  .signed-doc .signed-content .signed-blue-box {
    background: #2eb1e7;
    color: white;
    padding: 20px 10px;
    font-size: 14px;
    margin: 100px 20px;
    min-height: 180px;
    position: relative;
  }

  .signed-doc .signed-content .signed-blue-box p {
    text-align: left;
    font-size: 14px;
    font-weight: bolder;
    color: black;
    margin: 0 0 5px 0;
  }

  .signed-doc .signed-content .signed-blue-box h3{
    text-align: left;
    margin: 10px;
  }

  .signed-doc .signed-content .signed-blue-box li {
    text-align: left;
    margin: 10px;
  }

  .signed-doc .signed-content-close {
    position: absolute;
    top: -80px;
    right: 35px;
    width: 40px;
    height: 40px;
    z-index: 4;
    cursor: pointer;
  }

  .esig-signatures-hardware p {
    margin: 40px 0px 30px 0px;
    font-size: 17px;
  }

  .esig-signatures-hardware ul > p {
    margin: 10px 0px 10px 0px;
    font-size: 17px;
    font-weight: bolder;
    color: black;
  }

  .esig-signatures-hardware .esig-box {
    background-color: #f2f2f2;
    border-left: solid 5px #2eb1e7;
    padding: 15px;
    line-height: 200%;
    font-size: 17px;
    margin: 25px 0px;
  }

  .esig-signatures-hardware .esig-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 200%;
  }

  .esig-signatures-hardware .esig-box li {
    padding-left: 1em;
    text-indent: -.7em;
  }

  .esig-signatures-hardware .esig-box .innerli {
    padding-left: 3em;
    text-indent: -.7em;
  }

  .esig-signatures-hardware .esig-box li::before {
    content: "\2022";
    color: #2eb1e7;
  }
}
