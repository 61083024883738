.about {
  background: rgb(234, 236, 238);
  padding-bottom: 20px;

  .card {
    background: $white;
    border-radius: 10px;
    text-align: center;
    margin: 0 auto;
    padding: 28px 20px;
    position: relative;
    width: 245px !important;
    text-align: center;
    height: 100%;
    border-bottom: 11px solid #3CB0E6;

    @media only screen and (max-width: 420px) {
      width: 80%;
    }

    &__image {
      width: 94px;
      height: 94px;
      margin: 0 auto;
      text-align: center;

      img {
        width: 80px;
        height: 80px;
        display: block;
        margin: 0 auto;
        border-radius: 100%;
        border: 7px solid $white;
      }
    }

    &__icon {
      text-align: center;
      margin-bottom: 20px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 75%;
        display: block;
        margin: 0 auto;
      }

      p {
        margin: 0;
      }
    }

    &__text {
      position: relative;
      z-index: 1;

      p {
        margin: 0;
      }

      &--name {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #1F3B4E;
      }

      &--description {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: $light-blue;
      }

      &--icon {
        display: block;
        width: 100%;
        margin: 20px 0 14px;
      }

      &--quote-style {
        font-weight: normal;
        font-size: 13px;
        line-height: 1.5;
        color: #4F5D66;
        text-align: left;
      }
    }
  }

  .slick-slider {
    padding-bottom: 60px;

    .slick-list {
      padding: 0 0 1.25rem;

      .slick-track {
        display: flex !important;
        flex-wrap: wrap;

        .slick-slide {
          height: auto;

          & > div {
            height: 100%;
            text-align: center;
          }
        }
      }
    }

    .slick-arrow {
      top: 40%;

      &.slick-prev {
        transform: rotate(180deg);
        left: .5rem;

        &:before {
          content: url('../../images/slider-arrow.svg');
        }
      }

      &.slick-next {
        right: .5rem;

        &:before {
          content: url('../../images/slider-arrow.svg');
        }
      }
    }

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      bottom: 1.5rem;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;
        background: $light-blue;

        &.slick-active {
          width: 45px;
          background: #B0BABE;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
