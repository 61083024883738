.verification {
  ul {
    width: 100%;
    max-width: 720px;
    margin: 2rem auto 0;

    li {
      text-align: left;
      margin-bottom: 10px;
      padding-bottom: 25px;
      position: relative;
      padding-left: 35px;

      &:before {
        content: url('../../images/pipe.svg');
        position: absolute;
        top: 3px;
        left: 0;
      }

      strong {
        color: #3CB0E6;
      }
    }
  }

  img {
    width: 42rem;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}
