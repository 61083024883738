.prices {
  padding-bottom: 2rem;

  ul {
    padding: 0;
    width: 100%;
    max-width: 720px;
    margin: 2rem auto 1rem;

    li {
      text-align: left;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      padding-bottom: 25px;
      position: relative;
      padding-left: 35px;

      &:before {
        content: url('../../images/pipe.svg');
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
