.btn {
  background: $light-blue;
  border-radius: 50px;
  padding: 10px 20px;
  font-weight: 500;
  color: $white;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $light-blue;
    border-radius: 10rem;
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($light-blue, 15%);
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
  }

  &:hover {
    color: #fff;

    &:before {
      width: 100%;
    }
  }

  &:active {
    bottom: -1px;
  }

  &--delete {
    background-color: #ff6961;

    &:after {
      background-color: #ff6961;
    }

    &:before {
      background-color: darken(#ff6961, 15%);
    }
  }

  &--shop {
    span {
      background-color: darken($light-blue, 25%);
      border-radius: 999px;
      padding: 5px 8px;
      margin-left: 9px;
      font-size: 11px;
    }
  }

  img {
    margin-left: 14px;
    transition: all .25s ease;
  }

  &:hover {
    img {
      transform: translateX(10px);
      transition: all .25s ease;
    }
  }
}

a {
  text-decoration: none;

  .btn {
    text-decoration: none;
  }
}
