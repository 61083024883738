.faq {
  background: rgb(233, 236, 238);
  padding-bottom: 130px;

  &__inner {
    &--header {
      border-bottom: 1px solid rgb(176, 186, 190);

      @media only screen and (max-width: 992px) {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 1.25rem;
      }

      .nav-item {
        @media only screen and (max-width: 992px) {
          flex: 0 0 auto;
        }
      }

      .nav-link {
        color: $light-blue;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        padding: 15px 10px;
        margin-right: 60px;

        &.active {
          background: transparent;
          border-color: transparent;
          border-bottom: 3px solid #3CB0E6;
          position: relative;

          &:hover {
            border-bottom: 3px solid #3CB0E6;
          }

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $light-blue;
            position: absolute;
            bottom: -20px;
            left: calc(50% - 20px);
          }

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid rgb(233, 236, 238);
            position: absolute;
            bottom: -16px;
            left: calc(50% - 20px);
          }
        }

        &:hover {
          border: 1px solid transparent;
        }
      }
    }

    &--line {
      width: 100%;
      display: block;
      text-align: left;
      border-bottom: 1px solid rgb(176, 186, 190);
      position: relative;
      background: #D7E0E4;

      button {
        color: $light-blue;
        border: 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        background: transparent;
        padding: 30px;
        width: 100%;
        text-align: left;
      }

      .answer {
        div {
          padding: 10px 30px 30px 50px;

          p, ol, ul, li {
            font-weight: normal;
            font-size: 16px;
            line-height: 125%;
            color: #555555;
            margin-bottom: 25px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &#hogyan-tudok-akar {
        margin-top: 3rem;
      }
    }

    .btn-container {
      text-align: center;
      padding-top: 4rem;

      .btn {
        border-radius: 999px;
      }
    }
  }

  h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: #1F3B4E;
    margin: 40px 0 20px;
  }
}
