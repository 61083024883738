.econtracting {
  background: transparent url('../../images/hero_bg.jpg') no-repeat top center;
  background-size: 100%;
  background-color: rgb(233, 236, 238);
  padding-top: 8rem;

  @media only screen and (max-width: 991px) {
    background-size: 200%;
  }

  .awards {
    background: $white;
    display: flex;
    justify-content: space-around;

    div {
      padding: 0 40px;
      margin: 1rem auto 0;

      @media only screen and (max-width: 991px) {
        padding: 0;
      }

      img {
        margin-bottom: 1rem;
        height: 80px;
      }
    }
  }
}

h1 {
  font-weight: 800;
  font-size: 37px;
  line-height: 55px;
  text-transform: uppercase;
  color: #1F3B4E;
}

h3 {
  color: #1F3B4E;
}

.challenge {
  .card {
    margin-top: 2rem;
    width: 30%;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    img {
      width: 5rem !important;
      height: 6rem;
      padding: 0.75rem;
      margin: 2rem auto 0.5rem;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cards {
  display: flex;
  justify-content: space-around;

  .card {
    border: 0;
    max-width: 45%;
    padding: 1rem 2rem;
    text-align: center;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 1rem;
    }

    img {
      margin: 1rem auto;
      width: 4rem;
    }
  }
}

.usecases {
  .card {
    max-width: 100%;
    height: 100%;
    padding-bottom: 2rem;

    img {
      width: 4rem !important;
      height: 6rem;
      margin: 0 auto;

      &.contract {
        width: 6rem !important;
      }
    }

    @media only screen and (max-width: 991px) {
      margin-bottom: 3rem;
      height: auto;
    }
  }

  ul {
    margin: 2rem 0 0 0;
    padding: 0 0 0 4rem;
    text-align: left;

    li {
      margin-bottom: 0.5rem;
      list-style: disc;
    }
  }
}

.benefits {
  margin-top: 5rem;
  background-color: $dark;

  h1, p {
    color: $white;
  }

  h3 {
    color: #3CB0E6;
  }

  span {
    background-color: $dark;
    border: 2px solid #3CB0E6;
    color: $white;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin: 0 auto 1rem;
    font-weight: bold;
  }
}

.buttons {
  display: flex;
  justify-content: center;

  .btn {
    width: 12rem;
    margin: 0 1rem;
  }
}
