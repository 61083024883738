body {
  font-family: 'Karla', sans-serif !important;

  @media only screen and (max-width: 600px) {
    overflow-x: hidden;
  }
}

section {
  padding-top: 78px;

  @media only screen and (max-width: 600px) {
    padding-top: 32px;
  }
}
