// Reset
@import 'reset.css';

// Bootatrap
@import '~bootstrap/scss/bootstrap';

// Font
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');

// Slick
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// Variables
@import 'styles/variables/variables--colors';
@import 'styles/variables/variables--fonts';

// Elements
@import 'styles/elements/elements--buttons';

// Components
@import 'styles/components/components--header';
@import 'styles/components/components--footer';
@import 'styles/components/components--main';
@import 'styles/components/components--title';
@import 'styles/components/components--modal';

// Sections
@import 'styles/sections/sections--about';
@import 'styles/sections/sections--all';
@import 'styles/sections/sections--faq';
@import 'styles/sections/sections--hero';
@import 'styles/sections/sections--solutions';
@import 'styles/sections/sections--prices';
@import 'styles/sections/sections--sample';
@import 'styles/sections/sections--benefits';
@import 'styles/sections/sections--verification';

// Pages
@import 'styles/pages/pages--userform';
@import 'styles/pages/pages--dashboard';
@import 'styles/pages/pages--demo';
@import 'styles/pages/pages--econtracting';
@import 'styles/pages/pages--cart';
