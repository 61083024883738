header {
  background: $blue;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;

  .navbar {
    padding: 0;

    @media only screen and (max-width: 992px) {
      padding: 15px 0;
    }
  }

  .logo {
    width: 170px;
    display: inlin-block;
  }

  .btn {
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    margin-right: 25px;

    @media only screen and (max-width: 992px) {
      width: 10rem;
      margin: 0.75rem 0 0;
    }
  }

  a.link {
    color: #fff;
    padding: 10px 15px;
  }

  .navbar-toggler {
    background: $light-blue;
  }

  .dropdown {
    float: left;
    position: static;

    .dropbtn {
      font-size: 15px;
      border: none;
      outline: none;
      color: white;
      padding: 14px 16px;
      background-color: inherit;
      margin: 0;
      padding: 25px 0;

      i {
        margin-left: 5px;
      }
    }

    @media only screen and (max-width: 991px) {
      width: 100%;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: $white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 50%;
    right: 0;
    z-index: 1;

    @media only screen and (max-width: 991px) {
      display: flex;
      position: static;
      width: 100%;
    }
  }

  .dropdown:hover .dropdown-content {
    display: flex;
  }

  .column {
    width: 50%;
    padding: 25px 40px 25px 25px;
    min-height: 250px;

    a {
      float: none;
      color: #3CB0E6;
      font-size: 15px;
      font-weight: bold;
      text-decoration: none;
      display: block;
      text-align: left;
      padding-top: 5px;

      &:hover {
        color: #277fa8;
      }
    }

    span {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      height: auto;
    }
  }

  .scroll {
    display: inline-block;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:first-child {
      @media only screen and (min-width: 992px) {
        padding-right: 20px;
      }
    }

    @media only screen and (max-width: 992px) {
      background: $light-blue;
      border-radius: 999px;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: $white;
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      margin-bottom: 12px;
      min-width: 10rem;
      text-align: center;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $light-blue;
        border-radius: 10rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($light-blue, 15%);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
      }

      &:hover {
        color: #fff;

        &:before {
          width: 100%;
        }
      }
    }
  }

  .demo-toggler {
    display: flex;
    align-items: center;
    color: white;

    p {
      font-size: .75rem;
      margin: 0 10px;
    }

    input.apple-switch {
      position: relative;
      -webkit-appearance: none;
      outline: none;
      width: 50px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #D9DADC;
      border-radius: 50px;
      box-shadow: inset -20px 0 0 0 #fff;

      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        background: transparent;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
      }

      &:checked {
        box-shadow: inset 20px 0 0 0 $light-blue;
        border-color: $light-blue;

        &:after {
          left: 20px;
          box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
        }
      }
    }
  }
}
