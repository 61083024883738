.benefits-line {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #2eb1e7;
  margin: 40px 0px 50px 0px;
}

.benefits-line .benefits-vertical-line-left {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #2eb1e7;
  width: 2px;
  height: 25px;
}

.benefits-line .benefits-vertical-line-center {
  position: absolute;
  left: 50%;
  top: -25px;
  background-color: #2eb1e7;
  width: 2px;
  height: 50px;
}

.benefits-line .benefits-vertical-line-right {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #2eb1e7;
  width: 2px;
  height: 25px;
}

.key-benefit-image {
  width: 75px;
  margin-bottom: 1.5rem;
}

section.main-header-esig#sw div.blue-box {
  margin-top: 80px;
}

section.main-header-esig .header-overlay {
  padding: 230px 0px 170px 0px;
}

section.main-header-esig#sw .header-overlay {
  padding: 200px 0px 140px 0px;
}

section.main-header-esig .header-overlay h1 {
  margin-top: 0px;
}

section.movement-how-movements-speak h2 {
  margin: 40px 0px 20px 0px;
  font-size: 28px;
}

img.esig-signowise-logo {
  max-height: 65px;
  max-width: 390px;
  margin-bottom: 20px;
}

section.esig-digitise p {
  margin: 40px 0px 0px 0px;
  font-size: 17px;
}

section.esig-digitise h3 {
  text-transform: none;
  font-size: 24px;
  padding: 10px 60px;
}

div.esig-product-line-comparison div.esig-table-header {
  font-weight: bolder;
  background-color: transparent;
  padding: 0px;
  margin-top: 40px;
}

div.esig-product-line-comparison div.esig-table-header div {
  padding: 15px 5px;
}

div.esig-product-line-comparison div.esig-table-header img {
  max-width: 100%;
  max-height: 100%;
}

div.esig-product-line-comparison div.esig-table-content {
  display: table;
  height: 100%;
  width: 100%;
}

section.esig-product-line div.row {
  font-size: 16px;
  text-align: center;
  padding: 15px 5px;
}

div.esig-product-line-comparison div.esig-table-content {
  padding: 0px;
}

div.esig-product-line-comparison div.esig-table-content:nth-child(even) {
  background-color: #f6f6f6;
}

div.esig-product-line-comparison div.esig-table-content div {
  float: none;
  display: table-cell;
  vertical-align: middle;
  padding: 15px 5px;
}

div.esig-product-line-comparison div.esig-table-content div {
  border-color: #20303b;
  border-style: solid;
}

div.esig-product-line-comparison div.esig-table-content div:nth-child(1) {
  border-width: 0px;
}

div.esig-product-line-comparison div.esig-table-content div:nth-child(2) {
  border-width: 0px 2px 0px 2px;
}

div.esig-product-line-comparison div.esig-table-content div:nth-child(3) {
  border-width: 0px 2px 0px 0px;
}

div.esig-product-line-comparison div.buttons div:nth-child(2),
div.esig-product-line-comparison div.buttons div:nth-child(3) {
  border-bottom-width: 2px;
}

div.esig-product-line-comparison div.esig-table-header div:nth-child(1) {
  border-width: 0px;
}

div.esig-product-line-comparison div.esig-table-header div:nth-child(2) {
  border-width: 2px 2px 0px 2px;
}

div.esig-product-line-comparison div.esig-table-header div:nth-child(3) {
  border-width: 2px 2px 0px 0px;
}


div.esig-product-line-comparison div.esig-table-sub div:first-child {
  font-weight: bolder;
  font-size: 18px;
  vertical-align: middle;
  text-align: left;
}

section.esig-product-overview {
  background-color: #f2f2f2;
  padding: 60px 0px;
}

section.esig-product-overview div.list {
  font-size: 20px;
  margin: 40px 40px 0px 40px;
  padding: 0px 40px;
}

section.esig-product-overview div.list div.item {
  display: list-item;
  list-style: none;
  padding: 10px 0px 10px 40px;
  position: relative;
}

section.esig-product-overview div.list div.item::before {
  content: '';
  display: inline-block;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 10px;
  bottom: 0px;
  left: 0px;
  vertical-align: middle;
}

section.esig-for-banks {
  background-color: #f2f2f2;
  padding: 60px 0px;
}

section.esig-for-banks#swb {
  padding: 60px 0px;
}

section.esig-for-banks p {
  margin: 40px 0px 30px 0px;
  font-size: 17px;
}
section.esig-for-banks h2 {
  margin: 40px 0px 20px 0px;
  font-size: 28px;
}

section.esig-for-banks .signed-doc {
  position: relative;
  /* DIGITAL SEALS CLICK HERE PLACE */
  /* FORM FIELD CLICK HERE PLACE */
  /* E-SIGNATURES CLICK HERE PLACE */
}

section.esig-benefits {
  padding: 60px 0px;
}

section.esig-benefits p {
  margin: 20px 0px 20px 0px;
  font-size: 15px;
}

section.esig-for-banks .signed-doc .click-here-ds {
  position: absolute;
  top: 15%;
  left: 2%;
  width: 45%;
  height: 125px;
  cursor: pointer;
  /*background: rgba(0, 0, 0, 0.4);*/
}

section.esig-for-banks .signed-doc .click-here-ff {
  position: absolute;
  top: 40%;
  left: 29%;
  width: 35%;
  height: 125px;
  cursor: pointer;
  /*background: rgba(0, 0, 0, 0.4);*/
}

section.esig-for-banks .signed-doc .click-here-es {
  position: absolute;
  top: 65%;
  left: 29%;
  width: 35%;
  height: 175px;
  cursor: pointer;
  /*background: rgba(0, 0, 0, 0.4);*/
}

section.esig-for-banks .signed-doc .signed-content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
}

section.esig-for-banks .signed-doc .signed-content img {
  margin: 100px 20px;
}

section.esig-for-banks .signed-doc .signed-content .signed-blue-box {
  background: #2eb1e7;
  color: white;
  padding: 20px 10px;
  font-size: 14px;
  margin: 100px 20px;
  min-height: 180px;
  position: relative;
}

section.esig-for-banks .signed-doc .signed-content .signed-blue-box p {
  text-align: left;
  font-size: 14px;
  font-weight: bolder;
  color: black;
  margin: 0 0 5px 0;
}

section.esig-for-banks .signed-doc .signed-content .signed-blue-box h3{
  text-align: left;
  margin: 10px;
}

section.esig-for-banks .signed-doc .signed-content .signed-blue-box li {
  text-align: left;
  margin: 10px;
}

section.esig-for-banks .signed-doc .signed-content-close {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 40px;
  height: 40px;
  z-index: 4;
  cursor: pointer;
}

section.esig-signatures-hardware p {
  margin: 40px 0px 30px 0px;
  font-size: 17px;
}

section.esig-signatures-hardware ul > p {
  margin: 10px 0px 10px 0px;
  font-size: 17px;
  font-weight: bolder;
  color: black;
}

section.esig-signatures-hardware .esig-box {
  background-color: #f2f2f2;
  border-left: solid 5px #2eb1e7;
  padding: 15px;
  line-height: 200%;
  font-size: 17px;
  margin: 25px 0px;
}

section.esig-signatures-hardware .esig-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 200%;
}

section.esig-signatures-hardware .esig-box li {
  padding-left: 1em;
  text-indent: -.7em;
}

section.esig-signatures-hardware .esig-box .innerli {
  padding-left: 3em;
  text-indent: -.7em;
}

section.esig-signatures-hardware .esig-box li::before {
  content: "\2022";
  color: #2eb1e7;
}
