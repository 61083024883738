h3 {
  font-size: 31px;
  line-heigh: 46px;
  font-weight: 800;

  @media only screen and (max-width: 600px) {
    font-size: 28px;
  }
}

h4 {
  font-size: 24px;
  line-heigh: 36px;
  font-weight: 600;
}
